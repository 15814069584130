import React, { useState } from "react";
import _get from "lodash.get";

import Placeholder from "app/components/Placeholder";

import * as S from "app/components/Image.style";

const BasicImage = ({ url, image, className }) => {
  return (
    <img src={url || _get(image, "[0].url")} className={className} alt="" />
  );
};

const bgColors = {
  ivory: S.bgIvory,
  black: S.bgBlack,
  grayDark: S.bgGrayDark,
};

const OptimizedImage = ({
  bgColor,
  image: images,
  width,
  height,
  imgStyle,
}) => {
  const image = images?.[0];

  if (!image) {
    return null;
  }

  const imageUrl = image?.optmizedUrl;
  const aspectRatio =
    image?.width && image?.height
      ? `${image.width}/${image.height}`
      : undefined;

  const webpsrc = [
    image?.smallWebP ? `${image.smallWebP} 302w` : "",
    image?.mediumWebP ? `${image.mediumWebP} 608w` : "",
    image?.largeWebP ? `${image.largeWebP} 1080w` : "",
  ]
    .filter(Boolean)
    .join(",");

  return (
    <div
      css={[S.relative, S.wrapper]}
      style={{
        maxWidth: width,
        maxHeight: height,
        aspectRatio,
      }}
    >
      <div className="gatsby-image-wrapper">
        <div style={{ maxWidth: image?.width, maxHeight: image?.height }}>
          <img
            alt=""
            role="presentation"
            aria-hidden="true"
            src="data:image/svg+xml;charset=utf-8,%3Csvg height='1280' width='1920' xmlns='http://www.w3.org/2000/svg' version='1.1'%3E%3C/svg%3E"
            style={{ maxWidth: "100%", display: "block", position: "static" }}
          />
        </div>

        <Placeholder
          bgColor={bgColor}
          css={[S.imageStyle, _get(bgColors, bgColor)]}
          width={image?.width}
          height={image?.height}
        />

        <picture>
          {webpsrc && (
            <source
              type="image/webp"
              srcSet={webpsrc}
              sizes="(min-width: 608px) 608px, 100vw"
            />
          )}
          <img
            data-main-image
            src={imageUrl}
            srcSet={image.srcset}
            css={[S.imageStyle]}
            sizes="(min-width: 608px) 608px, 100vw"
            alt=""
            width={image?.width}
            height={image?.height}
            style={imgStyle}
          />
        </picture>
      </div>
    </div>
  );
};

const imageTypes = {
  "image/jpeg": OptimizedImage,
  "image/png": OptimizedImage,
  "image/gif": BasicImage,
};

const Image = ({ bgColor, image, ...props }) => {
  const mimeType = _get(image, `[0].mimeType`);
  const ImageType = _get(imageTypes, mimeType) || OptimizedImage;

  return <ImageType image={image} bgColor={bgColor} {...props} />;
};

export default Image;
