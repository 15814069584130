import { css } from "@emotion/react";

export { absolute, relative } from "app/styles/utils";

export const wrapper = css`
  height: 100%;
  width: 100%;
`;

export const placeholder = css`
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
`;

export const bgIvory = css`
  color: var(--ivory);
`;

export const bgGrayDark = css`
  color: var(--graydark);
`;

export const bgBlack = css`
  color: #000;
`;

export const imageStyle = css`
  bottom: 0;
  height: 100%;
  left: 0;
  margin: 0;
  max-width: none;
  padding: 0;
  position: absolute;
  right: 0;
  top: 0;
  width: 100%;
  object-fit: contain;
`;
