import React, { useEffect } from "react";
import { Link, useStaticQuery, graphql } from "gatsby";
import _get from "lodash.get";
import { Global } from "@emotion/react";

import { motion } from "framer-motion";

import Image from "app/components/Image";
import IconPlus from "app/components/icons/IconPlus";
import * as S from "app/components/TheForms.style";

import { useMeasure } from "react-use";
import useNavData from "app/hooks/useNav";
import { usePageContext } from "app/hooks/useSitePages";

export const FormItem = ({ displayTitle, title, uri, image, idx }) => {
  const [ref, { width }] = useMeasure();
  const { baseUri } = usePageContext();

  return (
    <motion.div
      disabled={!uri}
      initial={{ opacity: 0, x: -200, y: -200 }}
      animate={{ opacity: 1, x: 0, y: 0 }}
      exit={{ opacity: 0, x: -200, y: -200 }}
      transition={{ type: "easeIn", duration: 0.5, delay: idx * 0.1 }}
      css={[S.flexCenterAll, S.relative, S.formBox]}
      ref={ref}
    >
      <Link
        style={{ height: width, width: "100%" }}
        css={[S.flexCenterAll, S.imageBox]}
        to={`/${baseUri}/${uri}/`}
      >
        <Image
          css={S.image}
          image={image}
          objectFit="contain"
          objectPosition="50% 50%"
        />
        <span css={S.labelText}>{displayTitle || title}</span>
        <div css={[S.flexCenterAll, S.moreBtn]}>
          <IconPlus css={S.icon} />
        </div>
      </Link>
    </motion.div>
  );
};

const THE_SIX_FORMS = graphql`
  query TheSixFormsQuery {
    entry: craftTheSixFormsTheSixFormsEntry {
      id
      theSixForms {
        title
        slug
        uri
        id
        ... on Craft_sitePage_formPage_Entry {
          id
          displayTitle
          image {
            width
            height
            ... on Craft_pageImages_Asset {
              id
              optmizedUrl
              # imageFile {
              #   childImageSharp {
              #     gatsbyImageData(
              #       transformOptions: { fit: INSIDE }
              #       placeholder: NONE
              #       formats: [AUTO, WEBP]
              #     )
              #   }
              # }
            }
          }
        }
      }
    }
  }
`;

const TheForms = () => {
  const { setNavItems } = useNavData();
  const data = useStaticQuery(THE_SIX_FORMS);
  const forms = _get(data, "entry.theSixForms");

  useEffect(() => {
    setNavItems(undefined);
  }, [setNavItems]);

  return (
    <nav css={S.wrap}>
      <Global styles={S.formsGlobal} />
      {forms.map(({ id, ...form }, idx) => (
        <FormItem key={id} idx={idx} {...form} />
      ))}
    </nav>
  );
};

export default TheForms;
