import React, { useContext } from "react";
import { useStaticQuery, graphql } from "gatsby";
import _get from "lodash.get";

const ALL_PAGES = graphql`
  query AllPages {
    allSitePage {
      nodes {
        path
        context {
          baseUri
          id
          template
          subpages {
            id
            level
            lft
            title
            uri
          }
          level
          lft
          title
          uri
        }
      }
    }
    exhibitions: allCraftExhibitionsExhibitionsEntry {
      entries: nodes {
        id
        homeLabel
        homepage
        idleTimeout
        homeIcon {
          url
          ... on Craft_pageImages_Asset {
            svgContent
          }
        }
        uri
        explorations {
          id
        }
      }
    }
  }
`;

const PageContext = React.createContext();

export const PageContextProvider = ({ pageContext, children }) => (
  <PageContext.Provider value={pageContext}>{children}</PageContext.Provider>
);

export const usePageContext = () => {
  const pageContext = useContext(PageContext);
  return pageContext;
};

const useSitePages = () => {
  const pageContext = useContext(PageContext);
  const data = useStaticQuery(ALL_PAGES);
  const baseUri = _get(pageContext, "baseUri");

  const pages = _get(data, "allSitePage.nodes") || [];
  const sitePages = pages.filter(
    ({ context }) => _get(context, "baseUri") === baseUri
  );
  const currentExhibition = (_get(data, "exhibitions.entries") || []).find(
    ({ uri }) => uri === baseUri
  );

  const home = sitePages.find(
    ({ context }) => _get(context, "template") === "home"
  );
  const homePath = _get(home, "path") || "/";

  const galleryPages = sitePages
    .filter(({ context }) => _get(context, "template") === "gallery")
    .sort((a, b) => _get(a, "context.lft") - _get(b, "context.lft"));
  const gallery = galleryPages.find(({ context }) => !_get(context, "idx"));
  const galleryPath = _get(gallery, "path");

  return {
    ...pageContext,
    sitePages,
    galleryPath,
    homePath,
    galleryPages,
    currentExhibition,
  };
};

export default useSitePages;
